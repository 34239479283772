<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
       
        <v-toolbar  class="noprint">
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> Voters
                 <v-spacer/>
                 <span style="width: 290px; margin-top: 5px;">
                  <v-autocomplete
                  v-model="precinct"
                  :items="precincts"
                  outlined
                    dense
                  label="Select Precinct"
                  @change="this.genCode(byPrecinct)"
                  ></v-autocomplete>
                 </span>
               
               </v-toolbar>
               <div class="printable_table">
                <template v-for="(voter, index) in byPrecinct">
                    <div  :key="index" style=" padding: 10px; float: left; width: 350px; height: 210px; border: 1px solid #ddd;">
                      <div style="width: 150px;margin: auto;">
                      <img :src="$store.state.galing" width="150px"/>
                     </div> 
                      <div style="width: 100px; height: 100px; float: left; margin-right: 12px;">
                        <div :id="'qrcode_container_'+ voter.id" class="text-center"></div>
                      </div> 
                      <div style="width: 200px; height: 150px; float: left;">
                      
                            {{voter.lastname}},  {{voter.firstname}} {{voter.middlename}}
                            <br/>
                        PRECINCT #:  {{voter.prec}}
                        <br/>
                        CODE:  <strong>{{voter.voucher1}}</strong>
                       </div> 
                    </div>
                  </template>
               </div>
          </v-col>
         
    </v-row>
    </v-container>
    <va-voter-form :show="form"  :selected="voter" :state="state" @DialogEvent="formEvent" />
   
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'

  export default {
    data: () => ({
        form: false,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        user: {},
        search:'',
        areas:[],
        precinct:'0001A',
        precincts:[
"0001A",
"0001B",
"0001C",
"0001D",
"0002A",
"0002B",
"0003A",
"0003B",
"0003C",
"0004A",
"0004B",
"0004C",
"0004D",
"0005A",
"0005B",
"0005C",
"0005D",
"0006A",
"0006B",
"0006C",
"0007A",
"0007B",
"0007C",
"0008A",
"0008C",
"0008D",
"0008E",
"0009A",
"0009B",
"0009C",
"0010A",
"0010B",
"0010C",
"0011A",
"0011B",
"0011C",
"0012A",
"0012B",
"0012C",
"0013A",
"0013B",
"0013C"
],
        voter:{},
        voters:[],
        
        headers: [
          { text: 'Prec', value:"prec"},
            { text: 'Last Name', value:"lastname"},
            { text: 'First Name', value:"firstname"},
            { text: 'Middle Name', value:"middlename"},
            { text: 'Ext', value:"ext"},
             { text: 'Status', value:"status"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getUsers()
         
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-reponsive"
        }
      },
      byPrecinct() {
        var voters = this.voters.reduce((res,item)=>{
          if(item.prec == this.precinct) {
            res.push(item)
          }
          return res
        },[])

        return voters
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      transEvent(){
        this.trans_dailog = false
      },
      statusIndicator(_time) {
          //var current = this.$moment(_time)
          var a = this.$moment()
            var b = this.$moment(_time)
           var diff = a.diff(b, 'hour')
           if(diff <=  3) {
              return 'success'
           } else if(diff > 3 && diff < 5) {
               return '#A5D6A7'
           } else {
             return '#989898'
           }
      },
      CountBarangay(area){
          var _count = area.reduce((res,item)=>{
                res += item.barangays.length
                return res
          },0) 
          return _count
      },
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
            console.log(data)
              this.voter = data.voter
              this.Save()
          }
      },

      genCode(voters){
        for(var i=0;i<voters.length;i++) {
         let v_id=voters[i].id
         let voucher=voters[i].voucher1
            setTimeout(()=> {
              let _id = "qrcode_container_" + v_id
              var qrcode = new window.QRCode(document.getElementById(_id), {
              width : 100,
              height : 100  
          });
          qrcode.makeCode(voucher);
      
          }, 100)
        }
      
      },
      
      getUsers: function() { 
        this.$http.get("voter/list").then(response => {
          console.log(response.data)
            response.data.list != null?this.voters = response.data.list:this.voters =[]
            setTimeout(()=> {
             this.genCode(this.byPrecinct)
            }, 1000)
        }).catch(e => {
        console.log(e.data)
        })
     },
    
      Save: function() {
        let method = 'voter/register'
        this.state != 'new'?method='voter/update':method = 'voter/register'
        console.log(method, this.voter)
        this.$http.post(method, this.voter).then(response => {
            response.data.status? this.setAlertColor('success'): this.setAlertColor('error')
            this.setAlertText(response.data.message)
            this.setAlert(true)
            this.getUsers()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>